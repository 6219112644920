export const duckTrolleyIcon = (iconHex: string) => {
  return `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
   <defs id="defs1" />
   <g id="layer1"
     transform="matrix(0.9882614,0,0,0.98806725,-60.139586,-107.10983)"
     style="stroke-width:1.01198">
     <path
       id="path10"
       style="display:inline;fill:#ffffff;fill-opacity:1;stroke:#ffffff;stroke-width:17.6313;stroke-linecap:butt;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1"
       d="m 96.514943,108.30811 c -1.067479,-4.3e-4 -2.139817,0.0227 -2.732614,0.0681 -4.589128,0.35106 -8.114736,1.24755 -11.701486,2.97291 -8.409914,4.04541 -10.467589,9.76063 -11.667471,15.43191 -0.29082,1.37457 -0.406837,2.28901 -0.442207,3.50622 -0.03334,1.14736 -0.124989,1.54584 -0.510239,2.14458 l -0.238112,0.3631 c -1.369529,1.31376 -3.112079,2.05966 -4.977667,2.39422 -0.943444,0.18265 -1.701537,0.28367 -2.26773,0.28367 -0.306955,0.0138 -0.621004,0.0734 -0.895753,0.18155 0.02267,0.49591 -0.01705,0.99405 0.328821,1.39568 0.810044,1.14153 2.814642,2.68527 5.408535,4.153 1.06471,0.60246 4.439886,2.2331 5.726018,2.76867 0.437168,0.18205 0.829356,0.33741 0.873076,0.34041 l 0.01134,-0.0113 c 0.368973,0.15011 1.132723,0.48496 1.882216,0.77159 0.294229,0.11253 0.458568,0.19818 0.691657,0.29503 -4.263446,4.73131 -7.964595,9.7397 -8.016425,16.48717 -0.299558,20.52892 23.040627,23.99345 37.428878,24.18044 5.7827,0.17117 11.62461,-0.32168 17.16672,-1.44107 13.64504,-2.756 24.03236,-7.35476 26.18094,-27.90225 0.31356,-2.99242 0.41237,-8.29403 -2.12033,-11.8803 -2.26034,-3.20064 -4.05548,-4.72896 -6.20224,-5.28769 -4.17498,-1.08661 -12.85694,0.54694 -20.17146,0.37445 l -2.65324,-0.0567 c -0.0684,0.0188 -0.14596,0.038 -0.21543,0.0567 0.18535,-0.46407 0.3578,-0.95768 0.51023,-1.49781 1.12956,-4.00234 1.26593,-7.64869 1.26993,-10.49596 -0.0505,-6.7675 -3.27679,-13.51334 -11.39534,-17.37224 -2.56777,-1.2205 -5.19139,-1.87542 -8.560679,-2.14459 -0.574076,-0.0456 -1.642457,-0.079 -2.709937,-0.0794 z"
       transform="matrix(0.17225376,0,0,0.17212714,51.86546,92.176975)" />
    <path
       id="path7"
       style="display:inline;fill:${iconHex};stroke:#000000;stroke-width:5.87708;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1"
       d="m 96.514943,108.30811 c -1.067479,-4.3e-4 -2.139817,0.0227 -2.732614,0.0681 -4.589128,0.35106 -8.114736,1.24755 -11.701486,2.97291 -8.409914,4.04541 -10.344214,9.78819 -11.667471,15.43191 -0.514397,2.19391 -0.466342,2.86511 -1.190558,6.0139 -1.369529,1.31376 -3.112079,2.05966 -4.977667,2.39422 -0.943444,0.18265 -1.701537,0.28367 -2.26773,0.28367 -0.306955,0.0138 -0.621004,0.0734 -0.895753,0.18155 0.02267,0.49591 -0.01705,0.99405 0.328821,1.39568 0.810044,1.14153 2.814642,2.68527 5.408535,4.153 1.06471,0.60246 4.439886,2.2331 5.726018,2.76867 0.437168,0.18205 0.829356,0.33741 0.873076,0.34041 l 0.01134,-0.0113 c 0.368973,0.15011 1.132723,0.48496 1.882216,0.77159 0.294229,0.11253 0.458568,0.19818 0.691657,0.29503 -4.263446,4.73131 -7.964595,9.7397 -8.016425,16.48717 -0.299558,20.52892 23.040627,23.99345 37.428878,24.18044 5.7827,0.17117 11.62461,-0.32168 17.16672,-1.44107 13.64504,-2.756 24.03236,-7.35476 26.18094,-27.90225 0.31356,-2.99242 0.41237,-8.29403 -2.12033,-11.8803 -2.26034,-3.20064 -4.05548,-4.72896 -6.20224,-5.28769 -4.17498,-1.08661 -12.85694,0.54694 -20.17146,0.37445 l -2.65324,-0.0567 c -0.0684,0.0188 -0.14596,0.038 -0.21543,0.0567 0.18535,-0.46407 0.3578,-0.95768 0.51023,-1.49781 1.12956,-4.00234 1.26593,-7.64869 1.26993,-10.49596 -0.0505,-6.7675 -3.27679,-13.51334 -11.39534,-17.37224 -2.56777,-1.2205 -5.19139,-1.87542 -8.560679,-2.14459 -0.574076,-0.0456 -1.642457,-0.079 -2.709937,-0.0794 z"
       transform="matrix(0.17225376,0,0,0.17212714,51.86546,92.176975)" />
    <path
       style="display:inline;fill:#333333;fill-opacity:0.2;stroke:#000000;stroke-width:5.87708267;stroke-dasharray:none;stroke-opacity:1"
       d="m 115.72618,174.68759 c -1.37859,-0.11785 -2.7107,-0.37164 -3.87243,-0.73778 -0.80891,-0.25494 -2.82773,-1.22704 -3.66908,-1.76673 -1.91252,-1.2268 -2.80953,-2.66403 -3.08588,-4.94436 -0.18695,-1.54257 0.50228,-3.22777 2.15706,-5.27414 1.63779,-2.02535 4.57976,-4.52894 5.92303,-5.04043 0.98554,-0.37528 2.18054,-0.44603 2.92862,-0.17339 0.19431,0.0708 0.65215,0.33517 1.01741,0.58745 1.32702,0.91654 3.63601,1.84692 5.50033,2.21628 1.93746,0.38386 6.79032,0.3623 12.99319,-0.0577 1.54984,-0.10495 1.87779,-0.11265 1.92165,-0.0451 0.11016,0.1696 0.3222,1.19555 0.37305,1.805 0.34405,4.12355 -2.61737,7.9246 -8.23312,10.56737 -3.1506,1.48268 -6.40427,2.4246 -9.60181,2.77969 -1.02002,0.11328 -3.45838,0.16027 -4.35202,0.0839 z"
       id="path3"
       transform="matrix(0.17225376,0,0,0.17212714,51.86546,92.176975)" />
    <path
       style="display:inline;fill:#e77017;stroke:none;stroke-width:5.87708;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1"
       d="m 72.546015,143.97161 c -1.286132,-0.53557 -4.657059,-2.16741 -5.721769,-2.76987 -2.593893,-1.46773 -4.59973,-3.00528 -5.409774,-4.14681 -0.345873,-0.40163 -0.304751,-0.90804 -0.327423,-1.40395 0.274749,-0.1081 0.580101,-0.16815 0.887056,-0.182 0.566193,0 1.330208,-0.0925 2.273652,-0.27515 1.865588,-0.33456 3.610642,-1.08401 4.980171,-2.39777 l 0.07665,0.52822 c 0.09642,0.66447 0.38718,1.78545 0.610004,2.35177 0.362027,0.9201 0.766864,1.56486 1.955048,3.11366 0.616785,0.80399 1.109852,1.71211 1.310239,2.41318 0.27364,0.95734 0.441083,3.11949 0.240488,3.10534 -0.04372,-0.003 -0.437169,-0.15455 -0.874337,-0.3366 z"
       id="path2"
       transform="matrix(0.17225376,0,0,0.17212714,51.86546,92.176975)" />
    <path
       style="display:inline;fill:#333333;fill-opacity:0.2;stroke:#000000;stroke-width:5.87708;stroke-dasharray:none;stroke-opacity:1"
       d="m 77.94251,146.30701 c -4.564604,-2.72263 -8.874068,-13.58569 -7.526501,-19.53023 0.819495,-3.61505 3.258317,-11.37955 11.668231,-15.42496 3.58675,-1.72536 7.111101,-2.62043 11.700229,-2.97149 1.185594,-0.0907 4.28864,-0.0893 5.436792,0.002 3.369289,0.26917 5.993439,0.9276 8.561209,2.1481 8.11855,3.8589 11.3458,10.60694 11.39632,17.37444 -0.004,2.84727 -0.13778,6.49573 -1.26734,10.49807 -1.00274,3.55301 -2.72243,5.27793 -5.09608,7.57598 -4.4098,3.54939 -9.57084,6.00457 -16.074049,6.20238 -7.550927,0.22967 -12.483897,-2.10766 -18.798811,-5.87429 z"
       id="path4"
       transform="matrix(0.17225376,0,0,0.17212714,51.86546,92.176975)" />
    <path
       style="display:inline;fill:#020100;stroke:none;stroke-width:2.90796;stroke-opacity:1"
       d="m 76.509593,129.37154 c -1.558602,-0.35611 -1.966653,-2.77565 -0.82056,-4.86549 0.826299,-1.50671 2.180736,-2.24122 3.251132,-1.76308 0.841951,0.37609 1.287374,1.49931 1.118209,2.81978 -0.22747,1.77559 -1.350765,3.35656 -2.660632,3.74466 -0.436862,0.12944 -0.562596,0.13852 -0.888149,0.0641 z"
       id="path1"
       transform="matrix(0.34813014,0,0,0.34787424,38.820323,70.154654)" />
    <path
       style="display:inline;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:2.90796;stroke-opacity:1"
       d="m 76.972986,124.2566 c -0.91061,-0.0741 -1.73507,1.94261 -0.988825,2.51767 0.942761,0.7265 2.24461,-2.41551 0.988825,-2.51767 z"
       id="path6"
       transform="matrix(0.34813014,0,0,0.34787424,38.820323,70.154654)" />
    </g>
  </svg>`;
};
