import React from "react";
import { Link } from "react-router-dom";
import "./Services.css";

const Services = () => {
  return (
    <div className="stylized-index-page" id="services-index">
      <h1>APU Home Services</h1>
      <ul>
        <li>
          <Link to="/banner-admin">Banner Messages Admin Page</Link>
        </li>
        <li>
          <Link to="/campus-work-order">Campus Work Order</Link>
        </li>
        <li>
          <Link to="/cas-login-error">CAS Login Error</Link>
        </li>
        <li>
          <Link to="/google-workspace-widget">Google Workspace Widget</Link>
        </li>
        <li>
          <Link to="/global-search">Global Search</Link>
        </li>
        <li>
          <Link to="/trolley-tracker">Trolley Tracker</Link>
        </li>
        <li>
          <Link to="/trolley-eta-widget">Trolley ETA Widget</Link>
        </li>
        <li>
          <Link to="/maintenance">Maintentance</Link>
        </li>
        <li>
          <Link to="/one-card">One Card</Link>
        </li>
        <li>
          <Link to="/virtual-id-one-card">Virtual ID/One Card</Link>
        </li>
        <li>
          <Link to="/sso-test">SSO Test</Link>
        </li>
        <li>
          <Link to="/service-credits">Service Credits</Link>
        </li>
        <li>
          <Link to="/chapel-attendance">Chapel Attendance</Link>
        </li>
        <li>
          <Link to="/resource-page-menu">Resource Pages</Link>
        </li>
        <li>
          <Link to="/api/public/rss/everbridge/feed">Everbridge RSS</Link>
        </li>
      </ul>
    </div>
  );
};

export default Services;
