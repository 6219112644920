import React from "react";
import "./ServiceCreditsReport.css";

const ServiceCreditsReport = () => {
  return (
    <section id="service-credit-form">
      <div id="form">
        <form>
          <h2>Student Information</h2>
          <div id="label-student">
            <p id="title">Name:</p>
            <p>Name</p>
          </div>
          <div id="label-student">
            <p id="title">APU ID:</p>
            <p>emplid</p>
          </div>
          <div id="label-student">
            <p id="title">Address:</p>
            <p>address</p>
          </div>
          <div id="label-student">
            <p id="title">Phone:</p>
            <p>phone</p>
          </div>
          <div id="label-student">
            <p id="title">Email:</p>
            <p>email</p>
          </div>
          <div id="label-student">
            <p id="title">APU Box:</p>
            <p></p>
          </div>
          <p>
            *If any of the information listed above is inaccurate, please update
            via the Student Center in home.apu.edu, or contact the Registrar's
            Office.
          </p>
          <h2>Ministry Information</h2>
          <div id="label-ministry">
            <label>
              Name:{/**/}
              <input
                id="min-name"
                name="min-name"
                type="text"
                className="form-elements"
              />
            </label>
          </div>
          <div id="label-ministry">
            <label>
              Address 1:{/**/}
              <input
                id="min-address1"
                name="min-address1"
                type="text"
                className="form-elements"
              />
            </label>
          </div>
          <div id="label-ministry">
            <label>
              Address 2:{/**/}
              <input
                id="min-address2"
                name="min-address2"
                type="text"
                className="form-elements"
              />
            </label>
          </div>
          <div id="label-ministry">
            <label>
              City:{/**/}
              <input
                id="min-city"
                name="min-city"
                type="text"
                className="form-elements"
              />
            </label>
          </div>
          <div id="label-ministry">
            <label>
              State:{/**/}
              <input
                id="min-state"
                name="min-state"
                type="text"
                className="form-elements"
              />
            </label>
          </div>
          <div id="label-ministry">
            <label>
              Zip Code:{/**/}
              <input
                id="min-zip"
                name="min-zip"
                type="text"
                className="form-elements"
              />
            </label>
          </div>
          <div id="label-ministry">
            <label>
              Description:{/**/}
              <input
                id="min-descr"
                name="min-descr"
                type="text"
                className="form-elements"
              />
            </label>
          </div>
          <h2>Supervisor Information</h2>
          <div id="supervisor-label">
            <label>
              Name:{/**/}
              <input
                id="super-name"
                name="super-name"
                type="text"
                className="form-elements"
              />
            </label>
          </div>
          <div id="supervisor-label">
            <label>
              Phone:{/**/}
              <input
                id="super-phone"
                name="super-phone"
                type="tel"
                className="form-elements"
              />
            </label>
          </div>
          <div id="supervisor-label">
            <label>
              Email:{/**/}
              <input
                id="super_email"
                name="super_email"
                type="email"
                className="form-elements"
              />
            </label>
          </div>
          <h2>Service Information</h2>
          <div id="service-label">
            <label>
              Term:{/**/}
              <select
                name="service-term"
                id="service-term"
                className="form-elements"
              >
                <option value=""></option>
              </select>
            </label>
          </div>
          <div id="service-label">
            <label>
              Length:{/**/}
              <select
                name="service-length"
                id="service-length"
                className="form-elements"
              ></select>
            </label>
          </div>
          <div id="service-label">
            <label>
              Credits:{/**/}
              <select
                name="number-credits"
                id="number-credits"
                className="form-elements"
              ></select>
            </label>
          </div>
          <div id="number-credits-info">
            <p>5 Credits - 1 day of service (for 5+ hours)</p>
            <p>10 Credits - 2 days of service or weekend (for 5+ hours)</p>
            <p>15 Credits - Semester of weekly service (1-2 hours per week)</p>
            <p>
              30 Credits - Intensive week long, summer long, increased
              involvement (e.g. Mexico Outreach, Action Teams)
            </p>
          </div>
          <h2>Service Experience Essay</h2>
          <ul>
            <li>
              Please write a 250-500 word essay reflecting on your service
              experience.
            </li>
            <li>
              Here are some questions to guide your reflection:
              <ul>
                <li>
                  How did your service experience positively impact your faith
                  development?
                </li>
                <li>
                  What did you learn as a result of this service opportunity?
                </li>
                <li>
                  What were some ch allenges or difficulties you encountered?
                </li>
                <li>
                  How did this service experience influence your future goals or
                  calling?
                </li>
              </ul>
            </li>
          </ul>
          {/* TODO: set max-height: 250px min-height: 250px for id="editor" */}
          <div id="editor" className="form-elements"></div>
          <div id="counter">0 words</div>
          <br />
          <br />
          <button id="show-services-button" className="apu-button">
            Read Services Requirement for Graduation
          </button>
          <div id="services-requirement-info" className="hidden">
            <h4>Services Requirement for Graduation</h4>
            <p>
              Service, as one of the{" "}
              <a href="https://www.apu.edu/about/cornerstones/">
                Four Cornerstones
              </a>{" "}
              at Azusa Pacific University, is at the heart of our local and
              international outreach, missions, and service-learning endeavors.
              The university desires to see the service experience connect to a
              deeper relationship with Christ, develop a greater sense of
              community, and model scholarship put into practice. To this end,
              the university desires that every student has an intercultural
              experience during their time at APU.
            </p>
            <p>
              A student's service may correlate with his or her academic
              coursework or it may be an opportunity to branch out of the
              classroom experience. Students' involvement in ministry
              opportunities satisfies the graduation requirement of 120 service
              credits.
            </p>
            <p>
              The graduation requirement for full-time students is as follows:
            </p>
            <ul>
              <li>
                120 service credits for students beginning a fall semester as
                freshmen
              </li>
              <li>
                90 service credits for transfer students beginning a fall
                semester as sophomores
              </li>
              <li>
                60 service credits for transfer students beginning a fall
                semester as juniors
              </li>
              <li>
                30 service credits for transfer students beginning a fall
                semester as seniors
              </li>
            </ul>
            <p>
              APU recognizes the importance and value of service that happens
              on-campus as students strive to serve one another. One of the
              goals of the university, however, is to encourage students to
              pursue opportunities to serve local and global communities outside
              of APU. Therefore, the following criteria must be met in order for
              an experience to count towards service credits:
            </p>
            <ul>
              <li>
                The experience must take place outside of the APU community
              </li>
              <li>The experience must be unpaid</li>
              <li>The experience cannot be serving a family member</li>
              <li>
                Community events hosted on the APU campus may occasionally count
                towards service credits
              </li>
            </ul>
            <p>
              Through supervised, purposeful, and transformational service, APU
              students become consistent community contributors. Generally,
              students can earn credits according to the following guidelines:
            </p>
            <ul>
              <li>5 credits: one day service (must be 5+ hours in one day)</li>
              <li>
                10 credits: two day or weekend service project (each day must be
                5+ hours)
              </li>
              <li>
                15 credits: semester of weekly involvement (generally 1-2 hours
                per week totaling roughly 15-30 hours of service)
              </li>
              <li>
                30 credits: intensive week-long, summer long service or
                increased commitment and responsibility roughly equivalent to
                40+ hours (e.g. Mexico Outreach, Action Teams)
              </li>
            </ul>
            <p>
              APU encourages you to broaden your horizons through different
              experiences, and we also recognize that you may desire to stay
              with a particular service opportunity to continue developing your
              gifts and sustain relationships. APU fully supports this
              continuity of involvement and wants to encourage you to provide
              leadership in your endeavors.{/**/}
              <b>
                {" "}
                Each student can earn up to 60 credits maximum per academic year
                and up to 30 credits per semester (including summer)
              </b>
              {/**/}. The following restrictions exist:
            </p>
            <ul>
              <li>
                Athletic training may use up to four sessions for a maximum of
                60 credits.
              </li>
              <li>
                Music ministries may use up to a maximum of two ensembles for a
                maximum of 60 credits.
              </li>
            </ul>
            <p>
              <b>Attention Nursing Students:</b> The majority of your service
              credits will be completed through your service-learning courses.
              To ensure full completion of the service requirement, we advise
              that you complete 30 service credits prior to entering the nursing
              program.
            </p>
          </div>
          <br />
          <br />
          <div id="checkbox-container">
            <label id="read-and-agree-label">
              I have read and agree to abide by the University Service
              Requirement Policy. If I am shown to have falsified this
              application, I am subject to the Judicial process of Student Life.
              {/**/}
              <input
                type="checkbox"
                id="read-and-agree"
                name="read-and-agree"
                value="agree"
                required
              />
            </label>
          </div>
          <br />
          <br />
          <div id="button-container">
            <input
              type="submit"
              value="Save as Draft"
              id="row-button"
              className="apu-button"
            />
            <input
              type="submit"
              value="Submit"
              id="row-button"
              className="apu-button"
            />
          </div>
        </form>
      </div>
    </section>
  );
};

export default ServiceCreditsReport;
