import React, { useState, useEffect } from "react";
import uuid from "react-uuid";
import { useSearchParams } from "react-router-dom";
import ImageWithButton from "#components/ImageWithButton";
import { APULogoObject } from "#components/APULogo";

import eastcampus from "#images/eastcampus.jpeg";
import westcampus from "#images/westcampus.jpeg";
import regionalcampus from "#images/regionalcampus.jpeg";
import "./CampusWorkOrder.css";

const phoneNumbers = {
  facilities: "(626) 812-3002",
  campusSafety: "(626) 815-5000",
  facLink: function () {
    return this.facilities.replaceAll(/[- ()]/gi, "");
  },
  csLink: function () {
    return this.campusSafety.replaceAll(/[- ()]/gi, "");
  },
};

const locations = [
  {
    name: "East Campus (Azusa)",
    img: eastcampus,
    url: "https://azusapacificuniversity.webtma.com/Login/Validate/?tkn=kuauBnkwgWKPyqU9FrXSPAVVkezkNcKIbTd51gb5XlwvYbRw7fZ4wA5C0hIhw2q_jhxSaOGaWfEdQiM3SCx2cihBHWNFCp3gbd0yiWakJhjcvWBEOdPfbA7a-sTEqOM18dnP9bl-cZMtt-_eOeIxJEOlM5uH_O9r-GeYl6w3rT7nczx-Sw3kTnSPPOTGtmSbj5PuA27_qFkUfW5itIYzqGQwE45plErTJ-YbzAqT7ywG0qlBOjIlZpUdnrOc3QVRuCiSTLMAEzG9PgGeRVyZqfZTScEwTcbF6h5o5NoqWpVyNoH-5voDii3fNKbyuqMF",
    id: "east",
  },
  {
    name: "West Campus (Azusa)",
    img: westcampus,
    url: "https://azusapacificuniversity.webtma.com/Login/Validate/?tkn=kuauBnkwgWKPyqU9FrXSPLNwR1LAF_OoHCCjsdT5iawVdfuPTcW8P3wSyh-XUjyLkA-tJX2S7-oeSRs-40xSdLouJOr5NtjFhllrSzmfzIhfrx1qD0tAJSjMnCyPi3K2sFxiSNqrf4HM1t0CY8Q3U77cdxroYe2kqDfKlzlSAHS4cRHS9DpHiA2-G-GN1sJc3ZCoTCsIgZlMcK-fVaDLyYqtoJyW1UO1KzuRCiW0OLlu0AhgCqR2ebAsVy_TtP-EoRZk4VZlT6YnJpsKo3cS4V8iqwxngitQPp7INb6QiueZvvIpBT9ZVHXWRqI5QL4y",
    id: "west",
  },
  {
    name: "Regional Campus",
    img: regionalcampus,
    url: "https://azusapacificuniversity.webtma.com/Login/Validate/?tkn=kuauBnkwgWKPyqU9FrXSPLNwR1LAF_OoHCCjsdT5iaxGIf9mV7E05AB-n1YlhSAvJuZvI8URuWlQ0yg1Ftri0-daxfljy29sfn5Zht4rvcvSfegcv-sWkGW9nrfAPXzK9uHTqWo-Uei382KT0Gu_4u9AiBxmL9VVxKlkGJLrNl-4gz8W0lgO2GLyOxMYS91Re6AjP7XbovZqWixuLg6z-JsV57oPHFNoihzQ4DfLXk45TkNlrzVGXDbGWMAyqPW6-GzJMyZ-XBtwMfPqRryT5z6DDwJIISVX8Mj0agrAl-iuhqWRCQFTwsYO5jRoRioz",
    id: "regional",
  },
];

const CampusWorkOrder = () => {
  const [page, ,] = useSearchParams();
  const devMode = Boolean(page.get("dev") ?? false);

  const [alert, setAlert] = useState(false);
  const [emergency, setEmergency] = useState("");

  const now = new Date();
  const afterHours = new Date();
  const isWeekend = now.getDay() % 6 === 6;
  afterHours.setHours(16, 30, 0);
  devMode && now.setHours(17, 30, 0);

  const handleAfterHoursFormSubmission = (event: {
    preventDefault: () => void;
  }) => {
    event.preventDefault();
    if (emergency === "emergency") {
      window.alert(
        `Contact the Department of Campus Safety at ${phoneNumbers.campusSafety}.`,
      );
    } else {
      setAlert((alert) => !alert);
    }
  };

  useEffect(() => {
    //When if is NOT Mon-Fri: 8am-4:30pm, show the alert function and
    //let students know their options
    if (now > afterHours || isWeekend) setAlert(!alert);
  }, []);

  return (
    <section id="campus-work-order">
      <div className="header">
        <div className="column">
          <img id="logo" src={APULogoObject.hzPNG} alt="APU Logo" />
        </div>
        <div className="column">
          <h1>Campus Work Order</h1>
        </div>
        <div className="column">
          <div id="empty"></div>
        </div>
      </div>
      {alert ? (
        <div className="alert">
          <p id="alertmess">
            If this is an <strong>emergency</strong>, such as{" "}
            <strong>
              power outages, water leaks, or any other time sensitive issues
            </strong>
            , please contact the Department of Campus Safety at{" "}
            <a href={"tel:+" + phoneNumbers.csLink()}>
              {phoneNumbers.campusSafety}
            </a>
          </p>
          <form onSubmit={handleAfterHoursFormSubmission}>
            <div id="btns">
              <label>
                <input
                  type="radio"
                  id="emergencies"
                  name="emergencies"
                  value="emergency"
                  onClick={() => setEmergency("emergency")}
                />{" "}
                This is an emergency
              </label>
              <label>
                <input
                  type="radio"
                  id="notemergencies"
                  name="emergencies"
                  value="notemergency"
                  onClick={() => setEmergency("notemergency")}
                  required
                />{" "}
                This is <b>not</b> an emergency
              </label>
            </div>
            <input type="submit" id="submit" className="button" />
          </form>
        </div>
      ) : (
        <div className="content">
          <p>
            <strong>Select a campus to submit a work order</strong>
          </p>

          <div id="order">
            {locations.map((location) => (
              <ImageWithButton
                key={uuid()}
                name={location.name}
                href={location.url}
                id={location.id}
                src={location.img}
              />
            ))}
          </div>

          <div className="needhelp">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.apu.edu/facilities/contact/"
            >
              Need Help?
            </a>
          </div>

          <div className="contact">
            <div className="emergency">
              If this is an <strong>emergency</strong>, such as{" "}
              <strong>
                plumbing or electrical issues, gas smell, smoke, or fire
              </strong>
              <br />
              call the work order desk at&nbsp;
              <a href={"tel:+" + phoneNumbers.facLink()}>
                {phoneNumbers.facilities}
              </a>{" "}
              , Monday to Friday, 8 a.m. to 4:30 p.m.
            </div>
            <div className="after-hours">
              For <strong>after-hours emergencies</strong>, contact the
              Department of Campus Safety at&nbsp;{" "}
              <a href={"tel:+" + phoneNumbers.csLink()}>
                {phoneNumbers.campusSafety}
              </a>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
export default CampusWorkOrder;
