import uuid from "react-uuid";
import "./URLTable.css";

/*
 * Takes a multidimensional array that consists of arrays listing urls and names.
 * Prints this array into a table with two columns.
 */

interface Link {
  position: number;
  url: string;
  name: string;
}

const URLTable = (props: { links: Array<Link> }) => {
  // Format data in order to esily split into two coloums
  const tableRows: [Link, Link][] = [];
  props.links.forEach((link, index, arr) => {
    index % 2 == 0 && tableRows.push([link, arr[index + 1]]);
  });

  //
  return (
    <div id="tablebuild">
      <table>
        <tbody>
          {tableRows.map((linkObjArr: Link[]) => (
            <tr key={uuid()}>
              {linkObjArr.map((linkObj: Link) => (
                <th key={uuid()} className="cent">
                  <h4>
                    <a href={linkObj?.url} target="_blank" rel="noreferrer">
                      {linkObj?.name}
                    </a>
                  </h4>
                </th>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default URLTable;
