import React, { useEffect } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { Interweave } from "interweave";
import { useSearchParams } from "react-router-dom";

import "./Maintenance.css";
import URLTable from "#components/URLTable";
import { APULogoObject } from "#components/APULogo";
import { apiURL } from "#components/ApiUrlConverter";
import MaintenanceHeader from "#images/maintenance-header.png";

const maintLinksEndpoint = apiURL("/api/public/maintenance");

const homeBannerEndpoint = apiURL("/api/public/banners/home");

const Maintenance = () => {
  // URL parameters to set data for page
  const [page, ,] = useSearchParams();
  const pageName: string = page.get("page") ?? "home";
  const domainRef = {
    name: "APU Home",
    domain: "home.apu.edu",
  };

  // Call Home Service api for the Maint links
  const { isSuccess: maintIsSuccess, data: maintData } = useQuery(
    "maintLinks",
    () => axios.get(maintLinksEndpoint),
    {
      staleTime: Infinity,
    },
  );

  // Call Home Service api for an active home banner
  const { isSuccess: bannerIsSuccess, data: bannerData } = useQuery(
    "homeBanner",
    () => axios.get(homeBannerEndpoint),
    {
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    document.title = "Home Maintenance";
  }, []);

  switch (pageName) {
    case "fin":
      domainRef.name = "APU PeopleSoft Financial";
      domainRef.domain = "psfin.apu.edu";
      break;
    case "hr":
      domainRef.name = "APU PeopleSoft Human Resources";
      domainRef.domain = "pshr.apu.edu";
      break;
    default:
      domainRef.name = "APU Home";
      domainRef.domain = "home.apu.edu";
  }

  return (
    <div id="maintenance">
      {bannerIsSuccess && bannerData?.data?.announcement_text && (
        <div id="banner" className="banner-div">
          <div
            id="banner-message-text"
            className="banner-text"
            style={{ margin: "auto 0px" }}
          >
            <Interweave content={bannerData.data.announcement_text} />
          </div>
          <button
            id="banner-close"
            className="banner-x-button"
            onClick={() => {
              const banner = document.getElementById("banner");
              if (banner) {
                banner.style.display = "none";
              }
            }}
          >
            &#xD7;
          </button>
        </div>
      )}
      <div className="header">
        <div className="column">
          <img id="logo" alt="APU Logo" src={APULogoObject.hzWhPNG} />
        </div>
        <div className="column">
          <img id="maintimg" src={MaintenanceHeader} alt="Under maintenance" />
        </div>
        <div className="column" id="empty"></div>
      </div>
      <div className="container-fluid">
        <h2>TEMPORARY MAINTENANCE</h2>
        <div className="announcement">
          <p>
            Maintenance is being performed on <b>{domainRef.domain}</b>{" "}
            services.
            <br />
            Some features of <b>{domainRef.name}</b> are not available. <br />
            However, several APU resources are still available through direct
            links below.
          </p>
        </div>
        {/* INFO Table is populated from database */}
        {maintIsSuccess && <URLTable links={maintData?.data} />}
        <br />
      </div>
    </div>
  );
};
export default Maintenance;
