import L, { LatLngBounds } from "leaflet";
import {
  ImageOverlay,
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
} from "react-leaflet";
import { useSearchParams } from "react-router-dom";
import uuid from "react-uuid";
import { TrolleyDataObject, TrolleyData } from "#types/Trolley";
import "./TrolleyTracker.css";

import ChristmasTheme from "./themes/ChristmasTheme";
import { themes } from "./themes/ThemesData";
import trolleyMap from "#images/maps/new_trolley_map_red_white_overlay.png";

interface TrolleysDataRaw {
  trolleysDataRaw?: {
    Data?: TrolleyDataObject[];
  };
  mapDefaultZoom: number;
}

interface TrolleysDataStyle extends TrolleyData {
  iconStyle: number;
  seasonEasterEgg: string;
  mapDefaultZoom: number;
}

interface CenterZoom {
  center: [number, number];
  zoom: number;
}

// Vars
// maptiler token TODO move to env file
const accessToken = "8eatdYNztRnd5H45doKA";

// General Functions
// Calculate easter (https://gist.github.com/johndyer/0dffbdd98c2046f41180c051f378f343)
const flr = (num: number) => Math.floor(num);
const getEasterDate = (year: number, daysBefore = 0) => {
  // Golden Number - 1
  const gNum = year % 19;
  const cNum = flr(year / 100);
  // Related to Epact
  const hNum =
    (cNum - flr(cNum / 4) - flr((8 * cNum + 13) / 25) + 19 * gNum + 15) % 30;
  // Number of days from 21 March to the Paschal full moon
  const iNum =
    hNum - flr(hNum / 28) * (1 - flr(29 / (hNum + 1)) * flr((21 - gNum) / 11));
  // Weekday for the Paschal full moon
  const jNum = (year + flr(year / 4) + iNum + 2 - cNum + flr(cNum / 4)) % 7;
  // Number of days from 21 March to the Sunday on or before the Paschal full moon
  const lNum = iNum - jNum;
  const month = 3 + flr((lNum + 40) / 44);
  const day = lNum + 28 - 31 * flr(month / 4);

  // Return easter as a date object
  const thisEaster = new Date(year, month - 1, day - daysBefore);
  return thisEaster;
};

// Set Theme by season
const calcEasterEgg = (testMonth: number, testDate: number) => {
  // Show easter eggs for a given date range (month 0 = January)
  const today = new Date();
  if (testMonth) {
    today.setMonth(testMonth - 1);
    today.setDate(testDate);
  }

  // Add 3 weeks to easter for start date
  const thisEaster = new Date(getEasterDate(today.getFullYear(), -1));
  const startEaster = new Date(getEasterDate(today.getFullYear(), 21));

  // Graduation Easter Egg icon 2 weeks before and 1 week after
  const gradMonth = 5;
  const gradDay = 3;
  const startGrad = new Date(today.getFullYear(), gradMonth - 1, gradDay - 14);
  const thisGrad = new Date(today.getFullYear(), gradMonth - 1, gradDay + 7);

  // Date based easter egg themes
  if (today.getMonth() === 9) {
    return themes.Halloween;
  } else if (today.getMonth() === 11) {
    return themes.Christmas;
  } else if (today >= startEaster && today <= thisEaster) {
    return themes.Easter;
  } else if (today >= startGrad && today <= thisGrad) {
    return themes.Graduation;
  } else {
    return themes.Default;
  }
};

// Return Trolly Marker icon
// Var - iconStyle
const getTrolleyIcon = (
  iconStyle: number,
  mapDefaultZoom: number,
  seasonEasterEgg = "Default",
  iconHex = "#3244c9",
) => {
  // Adjust icon size based on zoom
  let bumpIconPercent = 1; // Default Value
  if (mapDefaultZoom < 15) {
    bumpIconPercent = 0.9;
  } else if (mapDefaultZoom > 15.5) {
    bumpIconPercent = 1.5;
  }

  const iconWidth = Math.round(
    themes[seasonEasterEgg].iconWidth * bumpIconPercent,
  );
  const trolleyIcon = themes[seasonEasterEgg].trolleyIcon(iconHex, iconStyle);

  const trolleyMarkReg = L.divIcon({
    html: trolleyIcon,
    iconSize: [iconWidth, iconWidth],
    iconAnchor: [iconWidth / 2, iconWidth / 2],
    popupAnchor: [0, (iconWidth / 2) * -1],
  });

  return trolleyMarkReg;
};

// Trolley Marker Component
// Create Marker Trolley Marker on location
// Vars - seasonEasterEgg
// Add iconStyle to getTrolleyIcon
const MapMarker = (props: TrolleysDataStyle) => {
  // fill in the element
  const trolleyMarker = props.trolleysData.map(
    (trolley: {
      VehicleName: string;
      VehicleId: number;
      LastStatusLat: number;
      LastStatusLon: number;
      Color?: string;
      ColorHex?: string;
    }) => (
      <Marker
        // key={trolley.VehicleId}
        key={uuid()}
        position={[trolley.LastStatusLat, trolley.LastStatusLon]}
        icon={getTrolleyIcon(
          props.iconStyle,
          props.mapDefaultZoom,
          props.seasonEasterEgg,
          trolley.ColorHex,
        )}
      >
        <Popup>
          I&apos;m the <b>{trolley.Color}</b> trolley!
        </Popup>
      </Marker>
    ),
  );

  return <>{trolleyMarker}</>;
};

// Zoom/Center Component
// Set the Zoom and Center based on Screen
const MapCenterZoom = (props: CenterZoom) => {
  const map = useMap();
  const center = props.center;
  map.setView(center, props.zoom);
  return null;
};

// Main Component
// Vars - devMode snowColor
// Add iconStyle to MapMarker
const MaptilerLeaflet = (props: TrolleysDataRaw) => {
  // Get url params
  const [page, ,] = useSearchParams();
  const testMonth = Number(page.get("month"));
  const testDate = Number(page.get("day") ?? 15);
  const snowColor = Number(page.get("snow") ?? 0);
  const iconStyle = Number(page.get("icon") ?? 1);
  const easterEggTheme = String(page.get("theme"));

  const trolleysData: TrolleyDataObject[] | undefined =
    props?.trolleysDataRaw?.Data;

  // Get EasterEggs
  const { seasonEasterEgg, mapStyle } = Object.keys(themes).includes(
    easterEggTheme,
  )
    ? themes[easterEggTheme]
    : calcEasterEgg(testMonth, testDate);

  // Compile url for map
  const mapURL = `https://api.maptiler.com/maps/${mapStyle}/{z}/{x}/{y}.png?key=${accessToken}`;

  // The coordinates where the map will be centered on the screen.
  const mapCenterCoords: [number, number] = [34.13285, -117.8933];

  const imageBounds = new LatLngBounds(
    [34.13716, -117.90233],
    [34.12707, -117.88137],
  );

  return (
    <MapContainer
      id="map"
      className="map"
      zoomControl={false}
      scrollWheelZoom={false}
      zoomSnap={0.5}
      zoomDelta={0.5}
    >
      <MapCenterZoom center={mapCenterCoords} zoom={props.mapDefaultZoom} />
      <TileLayer
        attribution='&copy; <a href="https://www.maptiler.com/copyright/">MapTiler</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url={mapURL}
        tileSize={512}
        zoomOffset={-1}
        crossOrigin={true}
        minZoom={14}
        maxZoom={16}
      />
      <ImageOverlay url={trolleyMap} bounds={imageBounds} />

      {trolleysData && (
        <MapMarker
          trolleysData={trolleysData}
          seasonEasterEgg={seasonEasterEgg}
          iconStyle={iconStyle}
          mapDefaultZoom={props.mapDefaultZoom}
        />
      )}

      {/* SNOW */}
      {seasonEasterEgg === "Christmas" && (
        <ChristmasTheme snowColorID={snowColor} />
      )}
    </MapContainer>
  );
};

export default MaptilerLeaflet;
