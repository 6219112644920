import { chmasTrolleyIcon } from "./ChristmasTheme";
import { hweenTrolleyIcon } from "./HalloweenTheme";
import easterRandomIcon from "./EasterTheme";
import { gradTrolleyIcon } from "./GraduationTheme";
import { duckTrolleyIcon } from "./Duck-A-ThonTheme";

interface Themes {
  [key: string]: {
    seasonEasterEgg: string;
    mapStyle: string;
    iconWidth: number;
    trolleyIcon(iconHex: string, iconStyle?: number): string;
  };
}

// Set Vars
const defaultIconWidth = 37;

// Data (some icons look better scaled up. I used percents for better handling of default adjustments)
export const themes: Themes = {
  Halloween: {
    seasonEasterEgg: "Halloween",
    mapStyle: "b0ef78b4-0036-4dd4-830e-eeba31b8f1d2",
    iconWidth: defaultIconWidth,
    trolleyIcon: (iconHex: string) => {
      return hweenTrolleyIcon(iconHex);
    },
  },
  Christmas: {
    seasonEasterEgg: "Christmas",
    mapStyle: "winter-v2",
    iconWidth: defaultIconWidth,
    trolleyIcon: (iconHex: string, iconStyle: number) => {
      return chmasTrolleyIcon(iconHex, iconStyle);
    },
  },
  DuckAThon: {
    seasonEasterEgg: "DuckAThon",
    mapStyle: "2a5bf32a-68eb-4b3d-a5e7-c86bbacaf844",
    iconWidth: defaultIconWidth * 1.02,
    trolleyIcon: (iconHex: string) => {
      return duckTrolleyIcon(iconHex);
    },
  },
  Easter: {
    seasonEasterEgg: "Easter",
    mapStyle: "511d9bf1-7e5c-4a8d-bd92-01d99ad339bb",
    iconWidth: defaultIconWidth,
    trolleyIcon: (iconHex: string) => {
      return easterRandomIcon(iconHex);
    },
  },
  Graduation: {
    seasonEasterEgg: "Graduation",
    mapStyle: "2a5bf32a-68eb-4b3d-a5e7-c86bbacaf844",
    iconWidth: defaultIconWidth * 1.1,
    trolleyIcon: (iconHex: string) => {
      return gradTrolleyIcon(iconHex);
    },
  },
  Default: {
    seasonEasterEgg: "Default",
    mapStyle: "2a5bf32a-68eb-4b3d-a5e7-c86bbacaf844",
    iconWidth: defaultIconWidth,
    trolleyIcon: (iconHex: string) => {
      return `<svg data-name="Layer 1" version="1.1" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <title>trolley_mark_COLOR</title>
        <circle cx="8.92" cy="9" r="6.5" fill="none" stroke="#fff" stroke-width="4px" />
        <circle cx="8.92" cy="9" r="6.5" stroke-miterlimit="10" fill=${iconHex} stroke="#000" />
        <circle cx="8.92" cy="9" r="2" />
      </svg>`;
    },
  },
};
