import { useState } from "react";
import { useQuery } from "react-query";
import { ClipLoader } from "react-spinners";
import axios, { AxiosError } from "axios";
import uuid from "react-uuid";
import $ from "jquery";

import Button from "#components/Button";
import { apiURL } from "#components/ApiUrlConverter";
import "./ServiceCreditsTable.css";
import DraftIcon from "#images/icons/icon_circle_draft.png";
import SWIcon from "#images/icons/icon_circle_supervisor_wait.png";
import SAIcon from "#images/icons/icon_circle_supervisor_affirm.png";
import SubmittedIcon from "#images/icons/icon_circle_submitted.png";
import CanceledIcon from "#images/icons/icon_circle_canceled.png";
import DeniedIcon from "#images/icons/icon_circle_denied.png";
import ApprovedIcon from "#images/icons/icon_circle_approved.png";

const contactURL = "https://apuosd.org/contact";
const contactName = "Office of Service and Discipleship";

interface SCR {
  EMPLID: string;
  APU_CSA_SEQ: number;
  APU_CSA_NAME: string;
  APU_CSA_CREDITS: string;
  DESCR: string;
  XLATSHORTNAME: string;
  SHORTNAME: string;
  APU_MIN_CLR_GRAD: string;
  INSTITUTION: string;
  ACAD_CAREER: string;
  STRM: string;
  FIELDNAME: string;
  FIELDVALUE: string;
  APU_MIN_CREDIT_NUM: number;
}

interface Credit {
  emplid: string;
  minimumCredits: number;
  sortedScrs: SCR[];
  envName: string;
  gradAppStatus: string;
  approvedCredits: number;
}

interface Key {
  icon: string;
  title: string;
  display: string;
}

const creditKeyObj: Key[] = [
  {
    icon: DraftIcon,
    title: "Draft",
    display: "Draft",
  },
  {
    icon: SWIcon,
    title: "Draft",
    display: "Supervisor Wait",
  },
  {
    icon: SAIcon,
    title: "Supervisor Affirm",
    display: "Supervisor Affirm",
  },
  {
    icon: SubmittedIcon,
    title: "Submitted",
    display: "Submitted",
  },
  {
    icon: CanceledIcon,
    title: "Cancelled",
    display: "Cancelled",
  },
  {
    icon: DeniedIcon,
    title: "Denied",
    display: "Denied",
  },
  {
    icon: ApprovedIcon,
    title: "Approved",
    display: "Approved",
  },
];

const formatTermString = (termString: string) => {
  // Find the width and height of the table
  const width = $("#service-credits").width();
  // If the width of the table is less than 735, create shortened strings
  if (width && width < 735) {
    const shortTermString = termString
      .replace(" B", "")
      .replace(" 20", " ")
      .replace("Summer", "Sum.")
      .replace("Spring", "Spr.");
    return shortTermString;
  } else return termString;
};

const getStatusIcon = (statusText: string) => {
  switch (statusText) {
    case "APRV":
      return ApprovedIcon;
    case "CNCL":
      return CanceledIcon;
    case "DENY":
    case "RVSD":
      return DeniedIcon;
    case "DRFT":
    case "OFFC":
      return DraftIcon;
    case "REVS":
      return SWIcon;
    case "RVSA":
      return SAIcon;
    case "SBMT":
      return SubmittedIcon;
    default:
      return DraftIcon;
  }
};

const ServiceCreditsTable = (props: Credit) => {
  if (props.minimumCredits == 0) {
    return (
      <div id="service-credits-no-req">
        <h2>You do not have a service credits requirement to fufill.</h2>
        <p>
          If you believe this is a mistake, please{" "}
          <a href={contactURL}>contact the {contactName}.</a>
        </p>
      </div>
    );
  } else {
    return (
      <div id="service-credits-table">
        <div id="service-credits-key">
          <ul>
            {creditKeyObj.map((keyItem) => (
              <li key={uuid()}>
                <img
                  key={uuid()}
                  className="status-icon"
                  src={keyItem.icon}
                  title={keyItem.title}
                  alt={keyItem.title}
                />
                <div>{keyItem.display}</div>
              </li>
            ))}
          </ul>
        </div>
        <table>
          <tr>
            <th colSpan={2}>Organization</th>
            <th>Credits</th>
            <th>Term</th>
            <th>Status</th>
          </tr>
          {props.sortedScrs ? (
            props.sortedScrs.map((scr: SCR) => (
              <tr key={uuid()}>
                <td key={uuid()} className="style6" colSpan={2}>
                  <a
                    href={`https://${
                      props.envName == "PRODSTU" ? "home" : props.envName
                    }.apu.edu/psc/${props.envName}/EMPLOYEE/HRMS/c/APU_CUSTOM.APU_CSA_SCR_SLFSVC.GBL?EMPLID=${props.emplid}&APU_CSA_SEQ=${
                      scr.APU_CSA_SEQ
                    }&cmd=login&auth=cas`}
                  >
                    {scr.APU_CSA_NAME}
                  </a>
                </td>
                <td>
                  <p>{scr.APU_CSA_CREDITS}</p>
                </td>
                <td>
                  <p>{formatTermString(scr.DESCR)}</p>
                </td>
                <td>
                  <img
                    className="status-icon"
                    src={getStatusIcon(scr.FIELDVALUE)}
                    title={scr.XLATSHORTNAME}
                    alt={scr.XLATSHORTNAME}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr></tr>
          )}
        </table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p className="style5">
            Total Approved Credits:{" "}
            <span
              className={
                props.approvedCredits &&
                props.minimumCredits &&
                props.approvedCredits > props.minimumCredits
                  ? "style5b"
                  : "style5c"
              }
            >
              {props.approvedCredits}
            </span>
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p className="style5">
            Estimated Required Credits:{" "}
            <span className="style5a">{props.minimumCredits}</span>
          </p>
        </div>
        {/* <{gradAppStatus ? getGradStatusString(gradAppStatus) : <></>}> */}
        <GetGradStatusString gradStatus={props.gradAppStatus} />
        <Button
          id="new_scr"
          href={`https://${
            props.envName == "PRODSTU" ? "home" : props.envName
          }.apu.edu/psc/${props.envName}/EMPLOYEE/HRMS/c/APU_CUSTOM.APU_CSA_SCR_SLFSVC.GBL?EMPLID=${props.emplid}&APU_CSA_SEQ=0&cmd=login&auth=cas`}
          name={["New Service Credit Report"]}
        />
        <p id="questions">
          *If you have any questions, please{" "}
          <a
            target="_blank"
            style={{ color: "#900", textDecoration: "underline" }}
            href={contactURL}
          >
            contact the {contactName}
          </a>
        </p>
      </div>
    );
  }
};

const GetGradStatusString = (props: { gradStatus: string }) => {
  const gradStatData = [
    {
      id: "CLR",
      status: "CLEARED",
      text: "Congratulations! You have completed the service credit requirements for graduation.",
    },
    {
      id: "NCLR",
      status: "PENDING",
      text: "You are still pending service credits to graduate. Please look at the above table and make sure that it accurately reflects your current number of credits. If you have recently submitted a service credit report, it is possible those reports are still being processed. Please contact the Office of Service and Decipleship to process your remaining service credits and receive a clearance for graduation.",
    },
    {
      id: "WALK",
      status: "Cleared to Walk",
      text: (
        <>
          You are eligible to participate in commencement and 'walk' in the
          graduation ceremony. You are still pending service credits and must
          submit them all in order for your degree to be posted. Please look at
          the above table and make sure that it accurately reflects your current
          number of credits. If you have recently submitted a service credit
          report, it is possible those reports are still being processed. Please{" "}
          <a href={contactURL}>contact the {contactName}. </a> to process your
          remaining service credits and receive clearance for your degree to
          post.
        </>
      ),
    },
  ];

  return (
    <>
      {gradStatData
        .filter((status) => props.gradStatus === status.id)
        .map((status) => (
          <>
            <p className="style9">Your Graduation Status:</p>
            <p className="style1">
              Our records indicate that you have started the Graduation
              application. To assist you, we have listed your current service
              credit standing below.
            </p>
            <p className="style1">
              Current Service Credit Status:{" "}
              <span className="style2">{status.status}</span>
            </p>
            <p className="style1">{status.text}</p>
          </>
        ))}
    </>
  );
};

const ServiceCreditsPage = () => {
  const endpoint = apiURL("/api/v1/users/scrs");

  const [sortedScrs, setSortedScrs] = useState([]);
  const [emplid, setEmplid] = useState("");
  const [envName, setEnvName] = useState("");
  const [gradAppStatus, setGradAppStatus] = useState("");
  const [minimumCredits, setMinimumCredits] = useState(0);
  const [approvedCredits, setApprovedCredits] = useState(0);

  const { isSuccess: scrsIsSuccess } = useQuery(
    "userProfile",
    () => axios.get(endpoint),
    {
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          window.location.reload();
        }
      },
      onSuccess: (scrsData) => {
        // Sort the scrs based on the STRM
        setSortedScrs(
          scrsData.data.sort((a: SCR, b: SCR) => {
            return Number(b.STRM) - Number(a.STRM);
          }),
        );
        // Set common attributes from the first name of the data
        setEmplid(scrsData.data[0].EMPLID);
        setEnvName(`${scrsData.data[0].SHORTNAME}STU`);
        setGradAppStatus(scrsData.data[0].APU_MIN_CLR_GRAD);
        setMinimumCredits(scrsData.data[0].APU_MIN_CREDIT_NUM);

        // Calculate the approved service credits
        scrsData.data.forEach((scr: SCR) => {
          if (scr.FIELDVALUE == "APRV") {
            setApprovedCredits(
              (prevCount) =>
                prevCount +
                (isNaN(Number(scr.APU_CSA_CREDITS))
                  ? 0
                  : Number(scr.APU_CSA_CREDITS)),
            );
          }
        });
      },
      staleTime: Infinity,
    },
  );

  return (
    <section id="service-credits">
      {scrsIsSuccess ? (
        <ServiceCreditsTable
          emplid={emplid}
          minimumCredits={minimumCredits}
          sortedScrs={sortedScrs}
          envName={envName}
          gradAppStatus={gradAppStatus}
          approvedCredits={approvedCredits}
        />
      ) : (
        <div className="cliploader-spinner">
          <ClipLoader color={"#700"} size={50} />
        </div>
      )}
    </section>
  );
};
export default ServiceCreditsPage;
